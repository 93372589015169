/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";
@import "primeflex/primeflex.css";

/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(../public/fonts/material-icons.woff2) format('woff2');
  }
  
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

/* cyrillic-ext */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(../public/fonts/roboto.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(../public/fonts/roboto.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(../public/fonts/roboto.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(../public/fonts/roboto.woff2) format('woff2');
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
  }
  /* math */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(../public/fonts/roboto.woff2) format('woff2');
    unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
  }
  /* symbols */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(../public/fonts/roboto.woff2) format('woff2');
    unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(../public/fonts/roboto.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(../public/fonts/roboto.woff2) format('woff2');
    unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(../public/fonts/roboto.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(../public/fonts/roboto.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(../public/fonts/roboto.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(../public/fonts/roboto.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(../public/fonts/roboto.woff2) format('woff2');
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
  }
  /* math */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(../public/fonts/roboto.woff2) format('woff2');
    unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
  }
  /* symbols */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(../public/fonts/roboto.woff2) format('woff2');
    unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(../public/fonts/roboto.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(../public/fonts/roboto.woff2) format('woff2');
    unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100 900;
    font-stretch: 100%;
    font-display: swap;
    src: url(../public/fonts/roboto.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

* {
    font-family: 'Roboto' , 'primeicons', sans-serif !important; 
}

body, html {
    margin: 0;
}

.backgroundLightEbemGreen {
    background-color: #E8EFCD !important;
}

.backgroundLightEbemGreenHalfWhite {
    background: linear-gradient( #E8EFCD 0 50%, white 50% 100%)  !important;
}

.backgroundDarkEbemGreen {
    background-color: #20260B !important;
}

.backgroundEbemGreen {
    background-color: #A2C138 !important;
}

dynamic-template {
    a {
        color: #3C99D7 !important;
        text-decoration: none !important;
    
        &:hover, &:focus, &:active, &:visited {
            color: #a2c137 !important;
            text-decoration: none !important;
        }
    }

    table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
        border: 1px solid #cdcdcd;
        margin-bottom: 15px;
        font-size: 14px;
        border-collapse: collapse;
        border-spacing: 0;
        font-size: 100%;
        font: inherit;
        margin: 0;
        padding: 0;
        position: relative;
        vertical-align: baseline;
    }
    
    table tr th {
        border: 1px solid #cdcdcd;
        width: 100%;
        padding: 0;
    }
    
    table tr td {
        border: 1px solid #cdcdcd;
        width: 100%;
    }

    .highlight {
        background-color: #eeff00;
        font-weight: bold;
    }

    li::marker {
        content: '➲ ';
        color: #1A699C;
    }
}
 
h1 {
    font-size: 72px !important;
    font-weight: 500 !important;
    color: #20260B !important;

    .material-icons {
        font-size: 72px !important;
    }
}

@media (max-width: 961px) {
    h1 {
        font-size: 48px !important;
        color: #20260B !important;

        .material-icons {
            font-size: 48px !important;
        }
    }  
}

h2 {
    font-size: 48px !important;
    font-weight: 500 !important;
    color: #20260B !important;

    .material-icons {
        font-size: 48px !important;
    }
}

@media (max-width: 961px) {
    h2 {
        font-size: 40px !important;
        color: #20260B !important;

        .material-icons {
            font-size: 40px !important;
        }
    }  
}

h3 {
    font-size: 40px !important;
    font-weight: 500 !important;
    color: #20260B !important;

    .material-icons {
        font-size: 40px !important;
    }
}

@media (max-width: 961px) {
    h3 {
        font-size: 32px !important;
        color: #20260B !important;

        .material-icons {
            font-size: 32px !important;
        }
    }  
}

h4 {
    font-size: 32px !important;
    font-weight: 500 !important;
    color: #20260B !important;

    .material-icons {
        font-size: 32px !important;
    }
}

@media (max-width: 961px) {
    h4 {
        font-size: 24px !important;
        color: #20260B !important;

        .material-icons {
            font-size: 24px !important;
        }
    }  
}

h5 {
    font-size: 24px !important;
    font-weight: 500 !important;
    color: #20260B !important;

    .material-icons {
        font-size: 24px !important;
    }
}

@media (max-width: 961px) {
    h5 {
        font-size: 20px !important;
        color: #20260B !important;

        .material-icons {
            font-size: 20px !important;
        }
    }  
}

h6 {
    font-size: 20px !important;
    font-weight: 500 !important;
    color: #20260B !important;

    .material-icons {
        font-size: 20px !important;
    }
}

@media (max-width: 961px) {
    h6 {
        font-size: 16px !important;
        color: #20260B !important;

        .material-icons {
            font-size: 16px !important;
        }
    }  
}

canvas {
    background: #F4F7EA !important;
}

.check-ebemGreen li::marker {
    content: '✓ ';
    color: #A2C138;
}

.arrows-ebemGreen li::marker {
    content: '➲ ';
    color: rgb(162, 193, 56);
}

.arrows-ebemBlue li::marker {
    content: '➲ ';
    color: rgb(26, 105, 156);
}


.check-ebemBlue li::marker {
    content: '✓ ';
    color: #1A699C;
}

.text-center {
    text-align: center;
  }
  
  .right-border {
      border-right: 1px solid #e0e0e0;
  }
  
  .gray {
      color: #757575 !important;

      :hover {
          color: #757575 !important;
      }

      :active {
          color: #757575 !important;
      }

      :visited {
          color: #757575 !important;
      }
  }

.row {
    display: flex !important;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: stretch;
}

.danger > .p-card {
    background-color: #FFEFEF !important;
    color: #971B1B !important;
}

.no-padding > .p-card > .p-card-body {
    padding: 0 5px !important;
}

.no-padding-top-bottom > .p-card > .p-card-body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.no-border {
    border: none !important;

    .p-inputtext {
        border: none !important;
    }
}

.no-radius {
    border-radius: 0 !important;
}

.backgroundEbemLightGreen {
    background-color: #D1E09B !important;
}

.backgroundEbemLightGreen > .p-card {
    background-color: #D1E09B !important;
}

.backgroundEbemLighterGreen {
    background-color: #E8EFCD !important;
}

.backgroundEbemLighterGreen > .p-card {
    background-color: #E8EFCD !important;
}

.backgroundEbemLightBlue {
    background-color: #CEE5F5 !important;
}

.backgroundEbemLightBlue > .p-card {
    background-color: #CEE5F5 !important;
}

.backgroundLightGray {
    background-color: #E9E9E9 !important;
}

.backgroundLightGray > .p-card {
    background-color: #E9E9E9 !important;
}

.ebemRed {
    color: #991B1B !important;
}

.ebemGreen {
    color: #A2C138 !important;
}

.hrEbemGreen {
    border-color: #A2C138 !important;
}

.ebemLightGreen {
    color: #D1E09B !important;
}

.ebemBlue {
    color: #1A699C !important;
}

.ebemLightBlue {
    color: #CEE5F5 !important;
}

.float-left {
    position: relative;
    float: left;
}

.float-right {
    position: relative;
    float: right;
}

.icon-small {
    width: 50px;
    height: 50px;
    margin-right: 0.25em;
    position: relative;
    top: 5px;
    display: inline-flex;

    @media(max-width: 961px) {
        display: flex;
    }
}

.icon {
    width: 99.17px;

    margin-right: 0.5em;
    display: inline-flex;

    @media(max-width: 961px) {
        display: flex;
    }
}

.material-icons {
    font-family: 'Material Icons' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
  
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
  
    /* Support for IE. */
    font-feature-settings: 'liga';
}

//Editor
.p-editor.ng-valid.ng-dirty > .p-editor-container > .p-editor-toolbar, .p-editor.ng-valid.ng-dirty > .p-editor-container > .p-editor-content {
    border-color: var(--p-primary-color) !important;
    color: var(--p-primary-color) !important;
}

.p-editor.ng-valid.ng-dirty::placeholder > .p-editor-container > .p-editor-toolbar, .p-editor.ng-valid.ng-dirty::placeholder > .p-editor-container > .p-editor-content {	
    color: var(--p-primary-color) !important;
}

.p-editor.ng-invalid > .p-editor-container > .p-editor-toolbar, .p-editor.ng-invalid > .p-editor-container > .p-editor-content {
    border-color: var(--p-form-field-invalid-border-color) !important;
    color: var(--p-inputtext-invalid-placeholder-color) !important;
}

.p-editor.ng-invalid::placeholder > .p-editor-container > .p-editor-toolbar, .p-editor.ng-invalid::placeholder > .p-editor-container > .p-editor-content {
    color: var(--p-inputtext-invalid-placeholder-color) !important;
}

.p-editor.ng-invalid.ng-touched > .p-editor-container > .p-editor-toolbar, .p-editor.ng-invalid.ng-touched > .p-editor-container > .p-editor-content {
    border-color: var(--p-form-field-invalid-border-color) !important;
    color: var(--p-inputtext-invalid-placeholder-color) !important;
}

.p-editor.ng-invalid.ng-touched::placeholder > .p-editor-container > .p-editor-toolbar, .p-editor.ng-invalid.ng-touched::placeholder > .p-editor-container > .p-editor-content {
    color: var(--p-inputtext-invalid-placeholder-color) !important;
}

//Menubar
p-menubar {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    width: 100%;
}

.p-menubar-start {
    background-color: #E8EFCD !important;
    display: flex;
    flex-grow: 1;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.p-menubar-mobile .p-menubar-button {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-around !important;
    position: absolute;
    right: 15px;
}

.p-menubar {
    background-color: #E8EFCD !important;
    border: none !important;
    align-items: flex-start !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    width: 100% !important;
}

.p-menubar-root-list {
    flex-direction: row !important;
    justify-content: flex-end !important;
    flex-wrap: nowrap !important;
}

.p-menubar-mobile-active .p-menubar-root-list {
    display: flex !important;
    flex-direction: row !important;
    top: 0 !important;
    left: auto !important;
    right: 50px !important;
    z-index: 1 !important;
    width: 25% !important;
    justify-content: flex-end !important;
    flex-wrap: nowrap !important;

    .p-menubar-item > .p-menubar-item-content > .p-menubar-item-link { border: none !important; }
}

.p-menubar-item-link {
    width: max-content !important;
}

//Mega menu
p-megamenu-sub {
    width: 100% !important;
}

.p-megamenu-mobile .p-megamenu-button {
    display: none !important;
}

.p-megamenu-start {
    background-color: #E8EFCD !important;
}

.p-megamenu-horizontal {
    align-items: none !important;
    padding: 0 !important;
}

.p-megamenu-mobile .p-megamenu-root-list {
    position: relative !important;
    margin: 0 !important;
    padding: 0 !important;
    list-style: none !important; 
    outline: 0 none !important;
    align-items: center !important;
    display: flex !important;
    flex-wrap: wrap !important;
    gap: var(--p-megamenu-gap) !important;
}

.p-megamenu-horizontal .p-megamenu-root-list {
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    gap: var(--p-megamenu-horizontal-orientation-gap) !important;
}

.p-megamenu-root-list {
    margin: 0 !important;
    padding: 0 !important;
    list-style: none !important;
    outline: 0 none !important;
    align-items: center !important;
    display: flex !important;
    flex-wrap: wrap !important;
    gap: var(--p-megamenu-gap) !important;
    border: none !important;
    box-shadow: none !important;
}

.p-megamenu {
    position: relative !important;
    display: flex !important;
    align-items: center !important;
    background: var(--p-megamenu-background) !important;
    border: none !important;
    border-radius: 0px !important;
    color: var(--p-megamenu-color) !important;
    gap: var(--p-megamenu-gap) !important;
}

.p-megamenu-sub {
    width: max-content !important;
}

.p-megamenu-mobile .p-megamenu-root-list .p-megamenu-item {
    position: relative !important;
}

.p-megamenu-button-align-right {
    height: 40px;
    float: right;
    position: relative !important;

    :hover {
        background-color: transparent !important;
    }

}

.backgroundEbemLighterGreen .p-tablist-tab-list, .p-megamenu-horizontal, .p-megamenu-root-list {
    background-color: #E8EFCD !important;
}

@media(max-width: 961px) {

    .p-megamenu-mobile .p-megamenu-root-list > .p-megamenu-item-active > .p-megamenu-overlay {
        display: block !important;
    }

    .p-megamenu-submenu {
        margin: 0 !important;
        list-style: none !important;
        padding: var(--p-megamenu-submenu-padding) !important;
        min-width: 12.5rem !important;
        display: flex !important;
        flex-direction: column !important;
        gap: var(--p-megamenu-submenu-gap) !important;
    }

    .p-megamenu-mobile .p-megamenu-root-list {
        position: relative !important;
        flex-direction: row !important;
        margin: 0 !important;
        padding: 0 !important;
        list-style: none !important; 
        outline: 0 none !important;
        align-items: center !important;
        display: flex !important;
        flex-wrap: wrap !important;
        gap: var(--p-megamenu-gap) !important;
    }

    .p-megamenu-horizontal .p-megamenu-root-list {
        display: flex !important;
        align-items: center !important;
        flex-wrap: wrap !important;
        gap: var(--p-megamenu-horizontal-orientation-gap) !important;
    }
    
    .p-megamenu-root-list {
        margin: 0 !important;
        padding: 0 !important;
        list-style: none !important;
        outline: 0 none !important;
        align-items: center !important;
        display: flex !important;
        flex-wrap: wrap !important;
        gap: var(--p-megamenu-gap) !important;
        border: none !important;
        box-shadow: none !important;
    }

    .p-megamenu {
        position: relative !important;
        display: flex !important;
        align-items: center !important;
        background: var(--p-megamenu-background) !important;
        border: none !important;
        border-radius: 0px !important;
        color: var(--p-megamenu-color) !important;
        gap: var(--p-megamenu-gap) !important;
    }

    .p-megamenu-mobile .p-megamenu-root-list .p-megamenu-item {
        position: relative !important;
    }
}

//Popover
.p-popover-content {
    display: flex !important;
}

.p-popover:after, .p-popover:before {
    display: none !important;
}

//Button 
.p-button {
    color: #20260B !important;

    border-radius: 8px !important;
}

.p-button-secondary {
    background: #CEE5F5 !important;
}

.p-button-link {
    text-decoration: underline !important;
}

.p-button-link-no-decoration {
    text-decoration: none !important;
}

//Input
.p-inputtext {
    width: 100%;
    border-radius: 8px !important;
}

.p-inputtext.ng-valid.ng-dirty {
    border-color: var(--p-primary-color) !important;
    color: var(--p-primary-color) !important;
}

.p-inputtext.ng-valid.ng-dirty::placeholder {
    color: var(--p-primary-color) !important;
}

.p-inputtext.ng-invalid {
    border-color: var(--p-form-field-invalid-border-color) !important;
    color: var(--p-inputtext-invalid-placeholder-color) !important;
}

.p-inputtext.ng-invalid::placeholder {
    color: var(--p-inputtext-invalid-placeholder-color) !important;
}

.p-inputtext.ng-invalid.ng-touched {
    border-color: var(--p-form-field-invalid-border-color) !important;
    color: var(--p-inputtext-invalid-placeholder-color) !important;
}

.p-inputtext.ng-invalid.ng-touched::placeholder {
    color: var(--p-inputtext-invalid-placeholder-color) !important;
}

p-inputnumber {
    width: 100%;
}

p-inputnumber.ng-valid {
    .p-inputtext.p-filled {
        border-color: var(--p-primary-color) !important;
        color: var(--p-primary-color) !important;
    }
    
    .p-inputtext.p-filled::placeholder {
        color: var(--p-primary-color) !important;
    }
}

p-inputnumber.ng-invalid {
    .p-inputtext {
        border-color: var(--p-form-field-invalid-border-color) !important;
        color: var(--p-inputtext-invalid-placeholder-color) !important;
    }
    
    .p-inputtext::placeholder {
        color: var(--p-inputtext-invalid-placeholder-color) !important;
    }
    
    .p-inputtext.ng-touched {
        border-color: var(--p-form-field-invalid-border-color) !important;
        color: var(--p-inputtext-invalid-placeholder-color) !important;
    }
    
    .p-inputtext.ng-invalid.ng-touched::placeholder {
        color: var(--p-inputtext-invalid-placeholder-color) !important;
    }
}

//Accordion
.p-accordioncontent-content {
    background-color: #ffffff !important;
    border-end-start-radius: 30px !important;
    border-end-end-radius: 30px !important;
}

//Checkbox
p-checkbox {
    border-color: #436807 !important;

    .p-checkbox-checked {
        .p-checkbox-box {
            background-color: #D1E09B !important;
            border-color: #436807 !important;
        }

        ::before
        {
            border-color: #436807 !important;
        }
    }
}

//Input group
p-input-group {
    border: 1px solid lightgray !important;
    border-radius: 8px !important;
    padding: 15px !important;
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    justify-content: flex-start !important;
    flex-direction: row !important;
    align-content: center !important;
    margin-bottom: 15px !important;

    p-inputgroup-addon {
        justify-content: flex-start;
    }

    &:has(.p-checkbox-checked) {
        border-color: #436807 !important;
        border-width: 2px !important;
        background-color: #D1E09B !important;
    }

    &:has(.p-radiobutton-checked) {
        border-color: #436807 !important;
        border-width: 2px !important;
        background-color: #D1E09B !important;
    }
}

p-inputgroup {
    border: 1px solid lightgray !important;
    border-radius: 8px !important;
    padding: 15px !important;
    display: flex !important;
    width: 100% !important;
    justify-content: flex-start !important;
    flex-direction: column !important;
    align-content: flex-start !important;
    margin-bottom: 15px !important;

    p-inputgroup-addon {
        justify-content: flex-start;
    }

    &.backgroundEbemLightGreen:has(.p-checkbox-checked) {
        border-color: #436807 !important;
        border-width: 2px !important;

        .p-checkbox-box {
            background-color: #D1E09B !important;
            border-color: #436807 !important;

            .p-checkbox-icon {
                background-color: #D1E09B !important;
            }

            .p-checkbox-icon::after {
                line-height: 0.5 !important;
                font-size: 125% !important;
                font-weight: bolder !important;
                content: '✓' !important;
                color: #436807 !important;
            }
        }
    }

    &.backgroundEbemLightGreen:has(.p-radiobutton-checked) {
        border-color: #436807 !important;
        border-width: 2px !important;

        .p-radiobutton-box {
            background-color: #D1E09B !important;
            border-color: #436807 !important;

            .p-radiobutton-icon {
                background-color: #D1E09B !important;
            }

            .p-radiobutton-icon::after {
                line-height: 0.5 !important;
                font-size: 125% !important;
                font-weight: bolder !important;
                content: '✓' !important;
                color: #436807 !important;
            }
        }
    }

    &.backgroundEbemLightBlue:has(.p-checkbox-checked) {
        border-color: #1A699C !important;
        border-width: 2px !important;

        .p-radiobutton-box {
            background-color: #CEE5F5 !important;
            border-color: #1A699C !important;

            .p-checkbox-icon {
                background-color: #CEE5F5 !important;
            }

            .p-checkbox-icon::after {
                line-height: 0.5 !important;
                font-size: 125% !important;
                font-weight: bolder !important;
                content: '✓' !important;
                color: #1A699C !important;
            }
        }
    }

    &.backgroundEbemLightBlue:has(.p-radiobutton-checked) {
        border-color: #1A699C !important;
        border-width: 2px !important;
        
            .p-radiobutton-box {
                background-color: #CEE5F5 !important;
                border-color: #1A699C !important;
    
                .p-radiobutton-icon {
                    background-color: #CEE5F5 !important;
                }
    
                .p-radiobutton-icon::after {
                    line-height: 0.5 !important;
                    font-size: 125% !important;
                    font-weight: bolder !important;
                    content: '✓' !important;
                    color: #1A699C !important;
                }
            }
    }
}

//Radio
p-radio-button {
    border-color: #436807 !important;

    .p-radiobutton-checked {
        .p-radiobutton-box {
            background-color: #D1E09B !important;
            border-color: #436807 !important;

            .p-radiobutton-icon {
                background-color: #436807 !important;
            }
        }
    }
}

.p-radiobutton.p-disabled .p-radiobutton-box {
    background: #efefef !important;
    border-color: gray !important;
    opacity: 0.25 !important;
}

//Select
.p-select {
    border-radius: 8px !important;
}

.p-select.ng-valid.ng-touched {
    border-color: var(--p-primary-color);

    .p-select-label {
        color: var(--p-primary-color) !important;
    }
}
.p-select.ng-invalid {
    border-color: var(--p-select-invalid-border-color);

    .p-select-label {
        color: var(--p-select-invalid-placeholder-color) !important;
    }
}

//Tabs
.p-tab-active {
    color: initial !important;
}

.p-tablist {
    overflow: auto !important;   
}

p-tabpanels {
    padding: 0 !important;
}

//Tag 
.p-tag {
    background-color: #D1E09B !important;
    color: #436807 !important;
    font-weight: normal !important;
    width: fit-content !important;
    display: inline-flex !important;
    padding: var(--p-tag-padding) !important;
    left: 5% !important;
    margin-top: 20px !important;
    position: relative !important;
}